import './App.css';
import { Typography } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Box from '@mui/material/Box';

function App() {

    return (
        <div className="App">
            <Box sx={{ display: 'flex', margin: 'auto' }}>
                <Box sx={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: '10%', padding: '10px' }}>
                    <img style={{ width: 250 }}  src="./logo.png" alt="logo" />
                    <Box sx={{ marginTop: '40px' }}>
                        <Typography color={"rgb(247, 247, 247)"} sx={{fontSize: 32, fontWeight: 400}}>See beyond the visible</Typography>
                    </Box>
                    <Typography color={"rgb(247, 247, 247)"} sx={{fontSize: 20, fontWeight: 200}}><i>We provide top-quality microscopes at unbeatable prices.</i></Typography>
                    <Box sx={{ margin: '10px 0', padding: '10px' }}>
                        <img src="./microscope.png" alt="microscope" width={'100%'} style={{ maxWidth: '650px' }} />
                    </Box>
                    <a href='https://www.amazon.com/dp/B0D3TFZ3JG' target='_blank' rel='noopener noreferrer'>
                        <Box sx={{ background: 'rgb(247, 247, 247)', display: 'flex', justifyContent: 'space-between', borderRadius: '25px', alignItems: 'center', marginBottom: '20px' }}>
                            <div>
                                <StoreIcon sx={{ padding: '10px' }} />
                            </div>
                            <div>
                                <Typography>Best Sellers</Typography>
                            </div>
                            <div></div>
                        </Box>
                    </a>
                    <a href='https://www.amazon.com/dp/B0D3TFZ3JG' target='_blank' rel='noopener noreferrer'>
                        <Box sx={{ background: 'rgb(247, 247, 247)', display: 'flex', justifyContent: 'space-between', borderRadius: '25px', alignItems: 'center' }}>
                            <div>
                                <BusinessCenterIcon sx={{ padding: '10px' }} />
                            </div>
                            <div>
                                <Typography>Order Form</Typography>
                            </div>
                            <div></div>
                        </Box>
                    </a>
                </Box>
            </Box>
        </div>
    );
}

export default App;